import React, { useState, useEffect } from "react";
import emailjs from "emailjs-com";
import ReCAPTCHA from "react-google-recaptcha";

export const Formulaire = () => {
  const [errors, setErrors] = useState({});
  const [recaptchaValue, setRecaptchaValue] = useState(null);

  useEffect(() => {
    const formInputs = document.querySelectorAll('#contactForm input, #contactForm textarea');
    let isValid = true;

    formInputs.forEach((input) => {
      if (!input.value.trim()) {
        isValid = false;
      }
    });

    // Vérifie si le ReCAPTCHA a été validé
  if (recaptchaValue) {
    isValid = true;
  }

    document.getElementById('submitBtn').style.display = isValid ? 'block' : 'none';
  }, [errors, recaptchaValue]);

  const handleInputChange = (event) => {
    setErrors({});
  };

  const resetForm = () => {
    document.getElementById("contactForm").reset();
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    // Validate reCAPTCHA
    if (!recaptchaValue) {
      alert("Veuillez valider le CAPTCHA.");
      return;
    }

    // Récupère les valeurs des champs du formulaire
    const firstName = event.target.elements.firstName.value;
    const lastName = event.target.elements.lastName.value;
    const email = event.target.elements.email.value;
    const message = event.target.elements.message.value;

    // Expressions régulières pour la validation
    const nameRegex = /^[A-Za-zÀ-ÖØ-öø-ÿ]+$/; // Valide le nom et le prénom (lettres et caractères accentués)
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Valide une adresse e-mail

    // Initialise un objet pour stocker les nouvelles erreurs
    const newErrors = {};

    // Validation du prénom
    if (!nameRegex.test(firstName)) {
      newErrors.firstName = "Veuillez remplir ce champ uniquement avec des lettres.";
    }

    // Validation du nom
    if (!nameRegex.test(lastName)) {
      newErrors.lastName = "Veuillez remplir ce champ uniquement avec des lettres.";
    }

    // Validation de l'adresse e-mail
    if (!emailRegex.test(email)) {
      newErrors.email = "Veuillez entrer une adresse mail valide.";
    }

    // Validation du message
    if (!message.trim()) {
      newErrors.message = "Veuillez écrire votre message.";
    }

    // Stocke les nouvelles erreurs dans l'état local
    setErrors(newErrors);

    // Si des erreurs sont présentes, ne pas envoyer la requête
    if (Object.keys(newErrors).length > 0) {
      return;
    }

    // Envoie les données du formulaire à EmailJS
    try {
      const templateParams = {
        firstName,
        lastName,
        email,
        message,
      };

      // Utilisation du service EmailJS
      await emailjs.send(
        "service_jucc1tf", // Remplacez par votre ID de service EmailJS
        "template_hl4nkdp", // Remplacez par votre ID de template EmailJS
        templateParams,
        "8ZMv5_XTn9TKIsLa4" // Remplacez par votre ID utilisateur EmailJS
      );

      alert("Formulaire envoyé avec succès !");
      resetForm(); // Réinitialise le formulaire
    } catch (error) {
      console.error("Erreur lors de l'envoi du formulaire :", error);
      alert("Une erreur s'est produite. Veuillez réessayer plus tard.");
    }
  };

  return (
    <div className="flex flex-col min-h-screen mt-16">
      <div className="flex justify-center xs:text-base xs:mt-32 xs:ml-1 sm:text-base sm:ml-2 md:text-base md:ml-4 lg:text-xl lg:ml-6">
        <p>Pour toutes informations supplémentaires ou pour nous contacter, veuillez remplir le formulaire ci-dessous. Nous vous répondrons dans les plus brefs délais. Merci !</p>
      </div>

      <main className="flex-grow flex items-center justify-center">
        <form id="contactForm" onSubmit={handleFormSubmit} className=" md:w-[28rem] flex flex-col rounded border border-[#2b2728] items-center p-8 sm:w-[20rem]" method="post">
          <label className="flex flex-col items-center justify-center">Prénom :
            <input className={`rounded mt-4 w-80 h-10 text-center border ${errors.firstName ? 'text-[#FF0000]' : 'border-[#2b2728]'} focus:outline-none focus:ring-2 focus:ring-[#0a62d0] focus:border-transparent`} type="text" name="firstName" placeholder="Entrez votre prénom" onChange={handleInputChange}/>{errors.firstName && <span className="text-[#FF0000] mt-2 text-xs">{errors.firstName}</span>}
          </label>
          <br />

          <label className="text-base flex flex-col items-center justify-center">Nom :
            <input className={`rounded mt-4 w-80 h-10 text-center border ${errors.lastName ? 'text-[#FF0000]' : 'border-[#2b2728]'} focus:outline-none focus:ring-2 focus:ring-[#0a62d0] focus:border-transparent`} type="text" name="lastName" placeholder="Entrez votre Nom" onChange={handleInputChange}/>{errors.lastName && <span className="text-[#FF0000] mt-2 text-xs">{errors.lastName}</span>}
          </label>
          <br />

          <label className="flex flex-col items-center justify-center">Adresse mail :
            <input className={`rounded mt-4 w-80 h-10 text-center border ${errors.email ? 'text-[#FF0000]' : 'border-[#2b2728]'} focus:outline-none focus:ring-2 focus:ring-[#0a62d0] focus:border-transparent`} type="email" name="email" placeholder="Entrez votre adresse mail" onChange={handleInputChange}/>{errors.email && <span className="text-[#FF0000] mt-2 text-xs">{errors.email}</span>}
          </label>
          <br />

          <label className="flex flex-col items-center justify-center">Message : 
            <textarea className={`rounded mt-4 w-80 h-24 rows=15 resize-none text-center border ${errors.message ? 'text-[#FF0000]' : 'border-[#2b2728]'} focus:outline-none focus:ring-2 focus:ring-[#0a62d0] focus:border-transparent`} name="message" placeholder="Entrez votre message"onChange={handleInputChange}/>{errors.message && <span className="text-[#FF0000] mt-2 text-xs">{errors.message}</span>}
          </label>
          <br />

          <ReCAPTCHA sitekey="6LewfKMpAAAAAFVDWhOdFyjOt5Az3iqwGRTLuAul" onChange={setRecaptchaValue} className="mt-4"/>
          <br />
          <button id="submitBtn" className="items-center rounded mt-6 h-10 w-32 border text-[#000000] bg-[#8C4FFF]" type="submit" style={{ display: 'none' }}>Envoyer</button>
        </form>
      </main>
    </div>
  );
};
