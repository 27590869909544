import React from 'react';

export const Politiques= () => {
return (
    <div className="min-h-screen flex justify-center items-center mt-36 text-center xs:text-xs sm:text-sm md:text-base lg:text-xl">
        <p className="w-[32rem] py-16">
        <h2>Politique de confidentialité de l'Association "La MERICOURTOISE", Musculation en autonomie</h2><br></br>
                Date d'effet : 01.01.2024<br></br>
                L'Association "La MERICOURTOISE" s'engage à protéger la confidentialité des informations personnelles que vous nous fournissez. Cette politique de confidentialité explique comment nous collectons, utilisons et protégeons vos données personnelles.<br></br><br />
      

        Collecte d'informations : <br></br>
            Nous collectons des informations lorsque vous envoyez un message via notre formulaire de contact. Les informations collectées sont limitées à :
            Nom et Prénom,<br></br>
            Adresse mail<br></br><br />

        Utilisation des informations : <br></br>
            Nous mettons en œuvre des mesures de sécurité pour protéger vos informations personnelles contre tout accès non autorisé, altération, divulgation ou destruction. L'accès aux informations personnelles est limité aux membres autorisés de l'Association qui ont besoin de ces informations pour effectuer des tâches spécifiques.<br></br><br />
      
        Protection des informations : <br></br>
            Nous ne partageons pas vos informations personnelles avec des tiers sans votre consentement explicite, sauf lorsque cela est requis par la loi.<br></br><br />
      
        Cookies : <br></br>
            Notre site web peut utiliser des cookies pour améliorer l'expérience utilisateur. Vous avez la possibilité de configurer votre navigateur pour refuser tous les cookies ou pour vous avertir lorsqu'un cookie est envoyé.<br></br><br />
      
        Accès et contrôle de vos informations : <br></br>
            Nous ne vendons, n'échangeons ni ne transférons vos informations personnelles identifiables à des tiers.<br></br><br />
      
        Consentement : <br></br>
            En utilisant notre site, vous consentez à notre politique de confidentialité.<br></br><br />

        Modifications de la politique de confidentialité : <br></br>
            Nous nous réservons le droit de modifier cette politique de confidentialité à tout moment. Toute modification sera publiée sur notre site web. Nous vous encourageons à consulter régulièrement cette politique pour rester informé de la manière dont nous protégeons vos informations.<br></br><br />
      
        Pour toute question ou préoccupation concernant notre politique de confidentialité, veuillez nous contacter à l'adresse suivante : fliyouette@hotmail.fr.</p>
    </div>
    );
};