import React from 'react';

export const Mentions = () => {
return (

    <div className="min-h-screen flex justify-center items-center mt-36 text-center xs:text-xs sm:text-sm md:text-base lg:text-xl">
    <p className="w-[32rem] py-16">

    <h2>Mentions légales</h2><br></br><br />

        Informations légales<br></br>

        Raison sociale : Association la MERICOURTOISE, Musculation en autonomie<br></br>

        Adresse : 2 Rue Du Loing 62680 Mericourt<br></br>

        Téléphone : 07.81.44.61.15<br></br>

        E-mail : fliyouette@hotmail.fr<br></br><br />

        Hébergement : <br></br>
        Le site est hébergé par HOSTINGER 1 Lordou Vironos Street 6023 Larnaca, Chypre<br></br><br />

        Directeur de publication : M.JERMOUL YACINE<br></br><br />

        Propriété intellectuelle : <br></br>

        L'ensemble du contenu présent sur le site de l'Association "La MERICOURTOISE", incluant les textes, images, graphiques, logos, et autres éléments, est la propriété exclusive de l'Association "La MERICOURTOISE" ou de ses fournisseurs de contenu, et est protégé par les lois nationales et internationales relatives à la propriété intellectuelle.<br></br><br />

        Protection des données personnelles : <br></br>

        Les informations personnelles collectées via le site de l'Association "La MERICOURTOISE" sont destinées à un usage interne. Conformément à la loi "Informatique et Libertés", vous disposez d'un droit d'accès, de modification, de rectification et de suppression des données vous concernant. Vous pouvez exercer ce droit en contactant l'Association à l'adresse : fliyouette@hotmail.fr.<br></br><br />

        Liens externes : <br></br>

        Le site de l'Association "La MERICOURTOISE" peut contenir des liens vers des sites externes. L'Association n'est pas responsable du contenu de ces sites, ni des éventuels dommages résultant de leur utilisation.<br></br><br />

        Modification des mentions légales : <br></br>

        L'Association "La MERICOURTOISE" se réserve le droit de modifier les présentes mentions légales à tout moment. Les utilisateurs sont invités à consulter régulièrement cette page pour prendre connaissance des éventuelles modifications.<br></br><br />

        Contact : <br></br>

        Pour toute question ou demande d'information concernant les mentions légales, veuillez contacter l'Association "La MERICOURTOISE" à l'adresse suivante : fliyouette@hotmail.fr.
    </p>
    </div>
    );
};