import React from 'react';
import { Assoc } from "../components/page_accueil/Assoc";

export const Accueil = () => {
  return (
    <div>
      <Assoc/>
    </div>
  );
}
